// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:35:58+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            3093783a90b48641eed65246cf959492
//    Signature:      eyJpdiI6IkxiOTJKcFFhemEzQ09SMXFCVEs3anc9PSIsInZhbHVlIjoiNkM0cStjSnR3WGVpXC9pRk5tZmpoTWNlTEFHMHNrc25ub3pIc1Vha1ZIUTBreENYa01vQTZNNzR2d3J6OGZjVm95NGdKZDN2SWV0SWZpXC92d2RjMVBndVJpOHJJYmlvbXdrRUh2V0FvK2xRQUlyNkR0WkpUQjBGeFVcL1lBNVJaM3JadHJoTnJxMTNMSllCSTlvckhrRmRMMWRTN2p1aitzSFRSa1RtbTl0WjBtNGo3UWNEZFEzSVErakFoc2VsK05jcXNPbHdBc1wvOWYrWVF6UVdRbFVEN1pWelI3b3RsZDQreGp1SmZzenVnRWZcLzVEbm1iaWdRMGV2TzhmR0RPaXg5TFltMkpvdUk0aDNuZDY2cXVLWEgxOUlkVFdFQXprb2xIXC9WUzIrb3ZJZlBIVTNQNVZ1ZTJRK0RIT2lVS1ZpMlNERjJEaDdRdHpcL3BIWklsM1wvcjdlZXlXY1p1bWtPWHowdEJnYUlcLzVGcWZyYlROUVcrU3hFSCtwajdycUIyN1wveW1QYTRVUTBuRXp3UDRkMG9UanQwVFE9PSIsIm1hYyI6IjA1M2IzNjEwYmI2M2FlYTA0ZjM3N2QyZTAwOTcyNTA2MjY1YjdkYjBhZWRhMGI3YmE2NGEwMmVlMzU4N2ZkZDAifQ==
if (!customElements.get('product-form')) {
    customElements.define('product-form', class ProductForm extends HTMLElement {
      constructor() {
        super();
  
        this.form = this.querySelector('form');
        this.form.querySelector('[name=id]').disabled = false;
        this.form.addEventListener('submit', this.onSubmitHandler.bind(this));
        this.cartNotification = document.querySelector('cart-notification');
        this.submitButton = this.querySelector('[type="submit"]');
      }
  
      onSubmitHandler(evt) {
        evt.preventDefault();
        if (this.submitButton.getAttribute('aria-disabled') === 'true') return;
  
        this.handleErrorMessage();
        // this.cartNotification.setActiveElement(document.activeElement);
  
        this.submitButton.setAttribute('aria-disabled', true);
        this.submitButton.classList.add('loading');
        this.querySelector('.loading-overlay__spinner').classList.remove('hidden');
  
        const config = fetchConfig('javascript');
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        delete config.headers['Content-Type'];
  
        const formData = new FormData(this.form);
        // formData.append('sections', this.cartNotification.getSectionsToRender().map((section) => section.id));
        formData.append('sections_url', window.location.pathname);
        config.body = formData;
  
        fetch(`${routes.cart_add_url}`, config)
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              this.handleErrorMessage(response.description);
  
              const soldOutMessage = this.submitButton.querySelector('.sold-out-message');
              if (!soldOutMessage) return;
              this.submitButton.setAttribute('aria-disabled', true);
              this.submitButton.querySelector('span').classList.add('hidden');
              soldOutMessage.classList.remove('hidden');
              this.error = true;
              return;
            }
  
            this.error = false;
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.submitButton.classList.remove('loading');
            if (!this.error) this.submitButton.removeAttribute('aria-disabled');
            this.querySelector('.loading-overlay__spinner').classList.add('hidden');
          });
      }
  
      handleErrorMessage(errorMessage = false) {
        this.errorMessageWrapper = this.errorMessageWrapper || this.querySelector('.product-form__error-message-wrapper');
        if (!this.errorMessageWrapper) return;
        this.errorMessage = this.errorMessage || this.errorMessageWrapper.querySelector('.product-form__error-message');
  
        this.errorMessageWrapper.toggleAttribute('hidden', !errorMessage);
  
        if (errorMessage) {
          this.errorMessage.textContent = errorMessage;
        }
      }
    });
  }
  